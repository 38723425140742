<template>
    <div class="resource-center-preview">
        <!-- <headers></headers> -->
        <div class="tabs-box">
            <div class="tabs-left">
                <div>
                    <p style="font-size:20pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:14pt; font-weight:bold">目</span><span
                            style="font-family:宋体; font-size:14pt; font-weight:bold">  </span><span
                            style="font-family:宋体; font-size:14pt; font-weight:bold">录</span></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc2302"><span
                                style="font-family:仿宋; font-size:10pt; font-weight:bold">一、项目概况</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc32574"><span
                                style="font-family:仿宋; font-size:10pt; font-weight:bold">二、学情统计汇报</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc12183"><span style="font-family:仿宋; font-size:10pt">（一） 项目整体学习情况</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc7086"><span style="font-family:仿宋; font-size:10pt">（二） 各区县学习情况</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc31930"><span
                                style="font-family:仿宋; font-size:10pt; font-weight:bold">三、实施过程</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc21393"><span style="font-family:仿宋; font-size:10pt">（一）项目前-实施准备</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc25418"><span style="font-family:仿宋; font-size:10pt">（二）项目中-运行保障</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc12496"><span style="font-family:仿宋; font-size:10pt">（三）项目后-分析总结</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc26076"><span
                                style="font-family:仿宋; font-size:10pt; font-weight:bold">四、问题</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc29394"><span style="font-family:仿宋; font-size:10pt">（一）学员信息素养有待加强</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc30929"><span style="font-family:仿宋; font-size:10pt">（二）跟岗沟通机制有待完善</span></a></p>
                    <p style="font-size:10pt; line-height:115%; margin:0pt 0pt 10pt"><a style="color:#000000"
                            href="#_Toc3234"><span style="font-family:仿宋; font-size:10pt">（三）培训保障政策有待落实</span></a></p>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <a name="_Toc2302"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">&#xa0;</span></p>
                </div>
            </div>
            <div class="tabs-right">
                <div>

                    <p
                        style="font-size:22pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:仿宋; font-size:22pt; font-weight:bold">2020年新疆和田地区幼儿园园长</span></p>
                    <p
                        style="font-size:22pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:仿宋; font-size:22pt; font-weight:bold">“云跟岗”培训项目总结</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:justify; text-indent:10.5pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">一、项目概况</span><span
                            style="-aw-bookmark-end:_Toc2302"></span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">为最大限度减少“新冠疫情”带来的影响，首师大京疆学院利用互联网技术，</span><span
                            style="font-family:仿宋; font-size:14pt">通过“脱产”的线上培训形式，依托首师大“双优云桥”项目平台，实现参训学员“云跟岗”，首都教育资源“云帮扶”。利用“反转式跟岗”形式，在京教师利用线上手段反向追踪学员在本单位的实时情况，并给予针对性的帮扶和指导。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc32574"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">二、学情统计汇报</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">根据项目要求，“双优云桥”项目平台承接本次项目实施工作，并定期对项目进展进行跟踪汇报，现对项目整体实施情况汇报如下</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:28pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">本次项目在2020年11月6日举行项目启动大会，11月9日正式步入学习阶段，2020年12月31日学习结束，历经八周。在项目学习阶段，</span><span
                            style="font-family:仿宋; font-size:14pt">线上培训涉及以下六个内容版块：提交日志（每日交流内容）、区县小组研修听课、个人学习体会提交（讲座）、集体研修听课（讲座）、班会参与时长、个人研修情况（每日指导）。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（1）</span><a
                            name="_Toc12183"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">项目整体学习情况</span></a></p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">本次线上培训涉及以下六个内容模块：提交日志（每日交流内容）、区县小组研修听课、个人学习体会提交（讲座）、集体研修听课（讲座）、班会参与时长、个人研修情况（每日指导）。各模块详情如下：</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <div style="text-align:center">
                        <table cellspacing="0" cellpadding="0"
                            style="border-collapse:collapse; margin:0 auto; width:411.65pt">
                            <tr style="height:24pt">
                                <td rowspan="12"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:52.6pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">总体</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">研修</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">情况</span></p>
                                </td>
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">日志提交情况</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">（总34次）</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">提交率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（次）</span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">77.78%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">26.45 </span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">区县小组研修</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span
                                            style="font-family:仿宋; font-size:12pt; font-weight:bold">听课时长（约10.5h）</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">完成率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（h）</span></p>
                                </td>
                            </tr>
                            <tr style="height:23.65pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">76.10%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">7.84 </span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">个人学习体会</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">提交情况（总7次）</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">提交率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（次）</span></p>
                                </td>
                            </tr>
                            <tr style="height:25.7pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">77.58%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">5.43 </span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">集体研修</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">听课时长（总18h）</span>
                                    </p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">完成率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（h）</span></p>
                                </td>
                            </tr>
                            <tr style="height:23.65pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">81.53%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">14.68 </span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">班会参与时长</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">（总5h）</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">完成率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（h）</span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">76.10%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">3.80 </span></p>
                                </td>
                            </tr>
                            <tr style="height:24pt">
                                <td rowspan="2"
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:145.8pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">个人研修情况</span></p>
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt; font-weight:bold">（总29次）</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">完成率</span></p>
                                </td>
                                <td
                                    style="background-color:#dadada; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">人均（次）</span></p>
                                </td>
                            </tr>
                            <tr style="height:25.75pt">
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:90.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">78.46%</span></p>
                                </td>
                                <td
                                    style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:115.75pt">
                                    <p style="font-size:12pt; line-height:150%; margin:0pt 0pt 10pt; text-align:center">
                                        <span style="font-family:仿宋; font-size:12pt">22.75 </span></p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">1.日志提交情况</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">本次学习中累计应该提交34次学习日志，65位学员中，提交17次及17次以上</span><span
                            style="font-family:仿宋; font-size:14pt">（完成度≥50%）的学员共有57人</span><span
                            style="font-family:仿宋; font-size:14pt">,</span><span
                            style="font-family:仿宋; font-size:14pt">占总人数的87.69%（其中全部完成即完成度为</span><span
                            style="font-family:仿宋; font-size:14pt">100%的有10位学员，占比15.38%；提交次数在28次以上34次以下（完成度80%-100%）的有26位学员，占比40%；提交次数在17次以上28次以下（完成度50%-80%）的有21位学员，占比32.31%）；</span><span
                            style="font-family:仿宋; font-size:14pt">提交次数低于17次（完成度＜50%）学员有8人，占总人数的12.31%。日志提交总体完成情况位77.78%，人均提交26.45次。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">  </span>
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.001.png" width="480" height="248" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">2.区县小组研修听课情况</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">区县小组研修每周一次按照区县分别进行，项目中共进行了7次区县小组研修，不同区县小组研修总时长略有差异（洛浦县研修总时长位11小时；墨玉县2组研修总时长位10.5小时；其他小组研修总时长10小时）。参与时长比例大于50%的有60人，占总人数的92.31%（其中有5位老师，全部完成，占比7.69%；有27位老师，完成度在80%-100%，占比41.54%；有28位老师，完成度在50%-80%成，占比43.08%；）；参与时长比例小于50%的有6人，占总人数的7.69%。区县小组研修听课时长的总体完成度为76.10%，人均参与7.84小时。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:仿宋; font-size:14pt"> </span><img
                            src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.002.png" width="480" height="250" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">3.个人学习体会提交情况：</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">个人学习体会提交情况共有7次。提交4次及以上（完成度&gt;50%）的学员有55人，占总人数的84.62%（其中全部提交即完成度为</span><span
                            style="font-family:仿宋; font-size:14pt">100%的有27位学员，占比41.54%；提交6次的有12位学员，占比18.46%；提交5次的有10位学员，占比15.38%；提交4次的有6位学员，占比9.23%）；提交次数低于4次的有10位学员，</span><span
                            style="font-family:仿宋; font-size:14pt">占总人数的15.38%。个人学习体会提交情况的总体完成度为77.58%，人均提交5.43次。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.003.png" width="556" height="272" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">4.集体研修听课时长</span><span
                            style="font-family:仿宋; font-size:14pt">：</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">集体研修听课时总时长为18小时，听课时长超多9小时（完成度&gt;50%）的学员有60人，占总人数的92.31%（其中参与时长18小时即完成度为</span><span
                            style="font-family:仿宋; font-size:14pt">100%的有5位学员，占7.69%；参与时长在15小时以上18小时以下的有33位学员，占比50.77%；参与时长在12小时以上15小时以下的有16位学员，占比24.62%；参与时长在9小时以上12小时以下的有6位学员，占比9.23%）</span><span
                            style="font-family:仿宋; font-size:14pt">；参与时长低于9小时（完成度＜50%）的学员有5人，占总人数的7.69%。集体研修听课时长的总体完成度位81.53%，人均参与14.63小时。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt 21pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.004.png" width="538" height="264" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">5.班会参与情况</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">本项目工召开4次班会，总计5小时。参与时长超过2小时30分（完成度&gt;50%）的学员有61人，占总人数的93.85%（其中参与时长超过4.5小时</span><span
                            style="font-family:仿宋; font-size:14pt">的有16位学员，占24.62%；参与时长在3.5小时以上4.5小时以下的有35位学员，占比53.85%；参与时长在2.5小时以上3.5小时以下的有10位学员，占比15.38%</span><span
                            style="font-family:仿宋; font-size:14pt">；没有全部完成的学员）；参与未达到2。5小时分钟的学员有4人，占总人数的6.15%。班会参与时长的总体完成情况为76.10%，人均参与3.8小时。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.005.png" width="550" height="256" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">6.个人研修情况</span><span
                            style="font-family:仿宋; font-size:14pt">：</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">根据项目规划，要求参训学员每日（周一至周五）都要与北京指导园长进行深度交流学习，从助教正式参与进项目至项目结束，累计应有29次个人研修。</span><span
                            style="font-family:仿宋; font-size:14pt">65位学员中，个人研修参与次数15次及以上</span><span
                            style="font-family:仿宋; font-size:14pt">（完成度＞50%）的学员共有57人</span><span
                            style="font-family:仿宋; font-size:14pt">,</span><span
                            style="font-family:仿宋; font-size:14pt">占总人数的87.69%（其中全部完成即完成度为</span><span
                            style="font-family:仿宋; font-size:14pt">100%的有11位学员，占比19.92%；</span><span
                            style="font-family:仿宋; font-size:14pt">个人研修参与次数</span><span
                            style="font-family:仿宋; font-size:14pt">在25次以上28次以下的有22位学员，占比33.85%；</span><span
                            style="font-family:仿宋; font-size:14pt">个人研修参与次数</span><span
                            style="font-family:仿宋; font-size:14pt">在20次以上25次以下的有16位学员，占比24.62%）；</span><span
                            style="font-family:仿宋; font-size:14pt">个人研修参与次数</span><span
                            style="font-family:仿宋; font-size:14pt">在17次以上20次以下的有8位学员，占比12.31%）；</span><span
                            style="font-family:仿宋; font-size:14pt">个人研修参与次数</span><span
                            style="font-family:仿宋; font-size:14pt">低于15次（完成度＜50%）学员有8人，占总人数的12.31%。</span><span
                            style="font-family:仿宋; font-size:14pt">个人研修参与次数</span><span
                            style="font-family:仿宋; font-size:14pt">总体完成情况位78.46%，人均参与22.75次。</span></p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.006.png" width="556" height="272" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">（2）</span><a
                            name="_Toc7086"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">各区县学习情况</span></a></p>
                    <p
                        style="font-size:14pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">本次培训将和田参训学员按照区域划分为五个区县小组，分别为和田市、和田县、洛浦县、墨玉县1组、墨玉县2组。五个区县小组各模块参与完成度如下：</span>
                    </p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:46.6pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">日志提交情况</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">区县小组研修听课时长</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">个人学习体会提交情况</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">集体研修听课时长</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">班会参与时长</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">个人研修情况</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">和田市研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">81.28%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">73.90%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">87.01%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">86.36%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">73.28%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">80.25%</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">和田县研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">94.54%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">83.81%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">93.88%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">92.14%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">82.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">86.45%</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">洛浦县研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">86.65%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">81.04%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">81.32%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">88.38%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">80.35%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">79.84%</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">墨玉县1组研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">67.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">73.50%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">64.84%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">79.26%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">80.48%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">67.37%</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">墨玉县2组研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">59.87%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">67.90%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">62.24%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">62.87%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">64.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt">78.08%</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.9pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">总体研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">77.78%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">76.10%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">77.58%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">81.53%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">76.10%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:仿宋; font-size:11pt; font-weight:bold">78.46%</span></p>
                            </td>
                        </tr>
                    </table>
                    <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.007.png" width="610" height="386" alt=""
                        style="-aw-left-pos:-16.9pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:233.75pt; -aw-wrap-type:none; margin:15px auto; display:block; overflow:hidden;" />
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0;display:block;">
                        <span
                            style="font-family:仿宋; font-size:14pt">由上图可见，和田县各研修模块完成度均高于其他区县小组，且各模块完成度均高于80%，这除了得益于项目组和北京指导园长的督促提醒也一定也离不开区县负责人的辛勤动员督促以及学员本身强烈的求知欲以及高涨的学习积极性。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">1.和田市研修情况</span></p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:38.6pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:93.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:66.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:justify">
                                    <span style="font-family:宋体; font-size:11pt">日志提交</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组研修</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人学习体会</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">集体研修</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">班会参与</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人研修</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:93.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">和田市研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:66.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">81.28%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">73.90%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">87.01%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">86.36%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">73.28%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">80.25%</span></p>
                            </td>
                        </tr>
                    </table>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.008.png" width="570" height="204" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;display:block;margin:0 auto" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">2.和田县研修情况</span></p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:33.35pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">日志</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组研修</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人学习体会</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:56.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">集体研</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:52.6pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">班会参与</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人研修</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">和田县研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">94.54%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">83.81%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">93.88%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:56.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">92.14%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:52.6pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">82.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">86.45%</span></p>
                            </td>
                        </tr>
                    </table>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.009.png" width="584" height="192" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline; margin:0 auto; display:block; " />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">3.洛浦县研修情况</span></p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:35.6pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">日志</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组研修</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人学习体会</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">集体研修</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">班会参与</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人研修</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">洛浦县研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">86.65%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">81.04%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">81.32%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">88.38%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">80.35%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">79.84%</span></p>
                            </td>
                        </tr>
                    </table>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.010.png" width="584" height="208" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">4.墨玉县1组研修情况</span></p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:29.85pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">日志</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组研修</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人学习体会</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">集体研修</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">班会参与</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人研修</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">墨玉县1组研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">67.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">73.50%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">64.84%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">79.26%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">80.48%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">67.37%</span></p>
                            </td>
                        </tr>
                    </table>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.011.png" width="584" height="204" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt; font-weight:bold">5.墨玉县2组研修情况</span></p>
                    <table cellspacing="0" cellpadding="0"
                        style="border-collapse:collapse; margin:10pt auto; width:466.55pt">
                        <tr style="height:27.6pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组</span></p>
                            </td>
                            <td
                                style="background-color:#e4dfec; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">日志</span></p>
                            </td>
                            <td
                                style="background-color:#fde9d9; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">区县小组研修</span></p>
                            </td>
                            <td
                                style="background-color:#daeef3; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人学习体会</span></p>
                            </td>
                            <td
                                style="background-color:#f2dcdb; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">集体研修</span></p>
                            </td>
                            <td
                                style="background-color:#f7fd9d; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">班会参与</span></p>
                            </td>
                            <td
                                style="background-color:#cfe09a; border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">个人研修</span></p>
                            </td>
                        </tr>
                        <tr style="height:24.2pt">
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:105.5pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">墨玉县2组研修情况</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:54.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">59.87%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:69.2pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">67.90%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:67.35pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">62.24%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:59.75pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">62.87%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.05pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">64.19%</span></p>
                            </td>
                            <td
                                style="border-bottom-color:#000000; border-bottom-style:solid; border-bottom-width:0.75pt; border-left-color:#000000; border-left-style:solid; border-left-width:0.75pt; border-right-color:#000000; border-right-style:solid; border-right-width:0.75pt; border-top-color:#000000; border-top-style:solid; border-top-width:0.75pt; padding-left:0.38pt; padding-right:0.38pt; padding-top:0.38pt; vertical-align:middle; width:49.7pt">
                                <p style="font-size:11pt; line-height:115%; margin:0pt 0pt 10pt; text-align:center">
                                    <span style="font-family:宋体; font-size:11pt">78.08%</span></p>
                            </td>
                        </tr>
                    </table>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:Calibri; font-size:10.5pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.012.png" width="586" height="192" alt=""
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inlin;margin:0 auto;display:block;" />
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc31930"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">三、实施过程</span></a></p>
                    <p
                        style="background-color:#ffffff; line-height:16.8pt; margin:0pt 0pt 10pt; text-align:justify; text-indent:21pt">
                        <span
                            style="font-family:仿宋; font-size:14pt">在整个培训过程中，“双优云桥”项目组为学员提供全流程的支持与服务，为学员能够正常、有序地在进行学习保驾护航。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">   </span><a name="_Toc21393"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">（一）项目前-实施准备</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">1.平台搭建准备以及操作指导培训：为了项目能够有序进行，学员能够顺利在线学习，“双优云桥”项目组在前期准备阶段根据项目特点以及不同角色的工作重点，针对不同角色制作操作手册以及注意事项，以操作者的角度详细阐述各功能的操作以及功能，每一操作步骤都配截图和文字说明图文并茂，帮助各角色巩固操作并指派专业人员对本项目所有使用平台对象进行操作演示讲解力争做到参训学员和授课教师当场就能听懂会用。针对个别未能完全掌握操作或未能参加前期操作培训的学员和指导园长进行单独沟通一对一指导，保证每一位项目参与者能操作，会操作，懂操作。</span>
                    </p><img src="./img/e4712b43-1b40-4667-a02f-20041a238c1f.013.png" width="478" height="180" alt=""
                        style="-aw-left-pos:20.25pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:19.7pt; -aw-wrap-type:none; margin-left:19.5pt; margin-top:18.95pt;margin:0 auto;display:block;" />
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（不同角色操作手册）</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0 position:relative;">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/14.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（操作讲解以及一对一指导）</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">2.助教筛选及培训：为了保证学习效果的优质性和高效性，项目组对助教的定义不能只是“学习管家”，还要是指导园长的“专业助手”，更要是参训学员的“第二导师”。助教除了要对学员的学习进行督促以外，也要能为学员提供专业的帮助，为指导园长提供专业的辅助。所以在助教方面，“双优云桥”项目组与京疆学院严格把控助教人选，每一名助教均经过严格筛选、培训、考察，适用合格者才能录用。1）初选-专业对口，本项目主要针对和田地区幼儿园长的专项培训，为了更好的对学员学习提供专业性的帮助，所以助教均来自首都师范大学学前教育专业；2）选拔-基础技能和总结能力，考虑到本次项目的特殊性-“云跟岗”，助教除了要专业对口之外，计算机等智能设备的运用和办公软件的熟练程度，以及理解总结概括能力也是非常关键的，所以在选拔阶段，“双优云桥”项目组与京疆学院召开意向助教会议，对项目要求主要工作等进行讲解，会后每位助教提交一份会议纪要，根据每位报名者提交会议纪要进行选拔，合格者进入适用阶段；3）适用-观察、反馈，适用阶段项目组根据助教的工作表现及学员或指导园长的反馈决定是否正式选用。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/15.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:150%; margin:0pt 0pt 10pt; orphans:0; text-align:center; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（助教招聘及选用）</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc25418"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">（二）项目中-运行保障</span></a>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">正式学习阶段，“双优云桥”项目组对项目的各个环节进行参与和把控，为每一次的直播做好保障，对学习中出现的问题随时进行解答，对学员学习情况实时监督提醒并每周进行汇总向京疆学院汇报。</span>
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/16.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/17.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:0pt; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（直播保障及研修统计）</span></p>

                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc12496"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">（三）项目后-分析总结</span></a>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">学习结束，“双优云桥”项目组对整体项目实施情况进行总结汇报，如对每一位的学员研修情况进行记录汇总，提交成果收集，各模块研修内容进行总结归纳，各区县整体研修进行分析等</span><span
                            style="font-family:仿宋; font-size:14pt">。</span></p>
                    <p style="font-size:12pt; line-height:150%; margin:0pt; orphans:0; text-align:center; widows:0">
                        <img src="./img/18.png" alt="100a2fb0c0088395d6b751252db0023"
                            style="-aw-left-pos:0pt; -aw-rel-hpos:column; -aw-rel-vpos:paragraph; -aw-top-pos:0pt; -aw-wrap-type:inline" />
                    </p>
                    <p
                        style="font-size:10.5pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:center; text-indent:0pt; widows:0">
                        <span style="font-family:宋体; font-size:10.5pt; font-weight:bold">（成果与分析总结）</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc26076"><span
                                style="font-family:仿宋; font-size:14pt; font-weight:bold">四、问题</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc29394"><span style="font-family:仿宋; font-size:14pt">（一）学员信息素养有待加强</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">项目依托首师大“双优云桥”项目平台，实现参训学员“云跟岗”，首都教育资源“云帮扶”。</span><span
                            style="font-family:仿宋; font-size:14pt">作为全新培训模式的探索与创新，项目充分借助网络信息化手段，实现参训学员、指导教师、项目助教的互动研修。但是在项目实施过程中，因学员个体信息化素养差异，出现了学员对于互动平台操作不熟练、网络工具使用意识不积极等现象，尤以培训前期较为明显，因此在</span><span
                            style="font-family:仿宋; font-size:14pt">教育信息化时代背景下,</span><span
                            style="font-family:仿宋; font-size:14pt">学习形式的改革与创新，需要</span><span
                            style="font-family:仿宋; font-size:14pt">教师</span><span
                            style="font-family:仿宋; font-size:14pt">主动</span><span
                            style="font-family:仿宋; font-size:14pt">提升</span><span
                            style="font-family:仿宋; font-size:14pt">自身</span><span
                            style="font-family:仿宋; font-size:14pt">信息素养，以适应当今的时代需求。</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc30929"><span style="font-family:仿宋; font-size:14pt">（二）跟岗沟通机制有待完善</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">和田参训教师与北京指导教师的在线实时交流与信息的及时反馈，是实现“云跟岗”预期目标的重要前提保障。</span><span
                            style="font-family:仿宋; font-size:14pt">由于地理原因，京疆两地存在两小时的时差，在项目实施过程中便出现过由于时差问题所导致的信息反馈不及时的现象，因此制定科学合理的沟通机制应是“云跟岗”项目亟待完善的内容之一，真正实现打破区域、时空限制的实时互动。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <a name="_Toc3234"><span style="font-family:仿宋; font-size:14pt">（三）培训保障政策有待落实</span></a></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">按照项目实施规划要求，参训学员以“脱产”形式完成本次培训，</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; widows:0">
                        <span
                            style="font-family:仿宋; font-size:14pt">项目实施过程中由于各种原因，很少有学员实现完全脱产培训，参训学员在学习同时还要兼顾日常工作，因此产生了不同程度的工学矛盾现象。希望地方教育部门能够积极出台相关政策，学员所在学校可以积极采取相应措施，协调参训教师学习期间的各项工作安排，妥善处理参训教师工作与学习的矛盾，尽最大可能保障参训教师全身心的参与到培训学习中，真正实现培训的预期效益。</span>
                    </p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                    <p
                        style="font-size:14pt; line-height:115%; margin:0pt 0pt 10pt; orphans:0; text-align:justify; text-indent:21pt; widows:0">
                        <span style="font-family:仿宋; font-size:14pt">&#xa0;</span></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {};
        },
        watch: {},
        methods: {
            // 	handle(groupId) {
            //     this.$router.push({path:"/resourcescenter",query:{type:'teacher',groupId:groupId}});
            // },
            handle(groupId) {
                this.$router.push({
                    path: "/resources",
                    // query: { type: "yin",groupId:groupId },
                });
            },
        },
        mounted() {},
        components: {},
    };
</script>

<style lang="less" scoped>
    .resource-center-preview {
        min-width: 990px;

        @media screen and (min-width:1250px) {
            min-width: 1200px;
        }

        min-height: 100vh;

        .tabs-box {
            p {
                font-family: Source Han Sans CN !important;
            }

            span {
                font-family: Source Han Sans CN !important;
            }

            display: flex;
            justify-content: space-between;
            // height: 80vh;
            width: 990px;
            margin: 0 auto;

            @media screen and (min-width:1250px) {
                min-width: 1200px;
            }


            .tabs-left {
                border: 1px solid #f8f8f8;
                // box-shadow: 0px 5px 29px 0px rgb(22 115 255 / 16%);
                border-radius: 2px;
                width: 200px;
                padding: 20px 40px;
                font-size: 14px;
                height: 80vh;
                overflow-y: auto;
                background: #fafafa;


            }

            .tabs-right {
                width: 100%;
                padding: 20px 40px;
                margin-left: 20px;
                background: #fafafa;
                flex: 1;
                border: 1px solid #f8f8f8;
                border-radius: 2px;
                height: 80vh;
                overflow-y: auto;

                // width: 200px;
                .font-weight {
                    font-family: 仿宋;
                    color: red;
                    font-weight: bold;
                    font-size: 16pt;
                    cursor: pointer;
                }

            }
        }
    }
</style>